<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="contact_information_form">
        <b-row>
          <b-col md="3" v-for="(vv,key) in previous_images" :key="key">
            <b-button @click="removeImage(key)" style="float: right;" variant="danger">X</b-button>
            <b-card
                title=""
                :img-src="imgShow(vv)"
                img-alt=""
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2"
            >
            </b-card>
          </b-col>
          <b-col md="12"></b-col>

          <b-col md="6">
            <b-card-text>
              <span>{{ $t('Title')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Title')"
                  rules="required"
              >
                <b-form-input name="title"
                    v-model="facility.title"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Write title here')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-card-text>
              <span>{{ $t('Image') }} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Image')"
                  :rules="facility.images?null:'required'"
              >
                <b-form-file multiple
                    v-model="images"
                    name="images[]"
                    accept=".jpg, .png, .jpeg"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Select one/multiple image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-text>
              <span>{{ $t('Description')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Description')"
                  rules="required"
              >
                <vue-editor v-model="facility.description" name="description"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('Write description here')" :editorToolbar="customToolbar" rows="5" ></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Row Loop -->
        <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
        >

          <!-- Mark from -->
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Title') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('item title')"
                  rules="required"
              >
                <b-form-input
                    v-model="item.title"
                    name="item_title[]"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('item title')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Image') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('image')"
                  rules="required"
              >
                <b-form-file
                    v-model="item.image"
                    name="item_image[]"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('item image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- grade name -->
          <b-col md="10">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Short Description') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('short description')"
                  rules="required"
              >
                <b-form-textarea
                    v-model="item.description"
                    name="short_description[]"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('item short description')"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col v-if="items.length>1" md="2" class="mb-50 mt-2">
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-2 mt-md-2"
                @click="removeItem(index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span></span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
          >
            <feather-icon
                icon="PlusIcon"
                class="mr-25"
            />
            <span>{{ $t('Add More') }}</span>
          </b-button>
          <!-- submit button -->
          <b-col>
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              {{ $t('Submit') }}
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BButton,BCardText,BFormGroup,BFormFile,BFormTextarea,BCard,
} from 'bootstrap-vue'
import {
  required,email,
} from '@validations'
import apiCall from "@/libs/axios";
import { VueEditor } from "vue2-editor";
import Ripple from "vue-ripple-directive";
import {heightTransition} from "@core/mixins/ui/transition";
export default {
  name:'Facility',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,BFormFile,
    BRow,BFormGroup,
    BCol,BFormTextarea,
    BButton,BCard,
    BCardText,VueEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,email,
      images:[],
      previous_images:[],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
      items: [{
        id: 1,
        title: '',
        image:null,
        previous_image:null,
        description: '',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created(){
      window.addEventListener('resize', this.initTrHeight);
      this.$store.dispatch('GET_ALL_FACILITY');
      this.pImItem();
  },
  methods: {
    jsonDecode(dt){
      try{
        return JSON.parse(dt);
      }catch(e){
        return [];
      }
    },
    /*image main path get*/
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
    removeImage(index){
      this.previous_images.splice(index,1);
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.contact_information_form);
          data.append('description',this.facility.description);
          data.append('previous_images',JSON.stringify(this.previous_images));
          apiCall.post('/facility/update/store',data).then((response)=>{
            if (response.data.status==='success'){
              this.images=[];
              this.$toaster.success(response.data.message);
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_FACILITY');
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form_add_or_update.scrollHeight)
      })
    },
    /*set previous image and items*/
    pImItem(){
      if(this.facility){
        this.previous_images=this.jsonDecode(this.facility.images);
        this.items=this.jsonDecode(this.facility.items);
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed:{
    facility(){
      return this.$store.getters.facility;
    }
  },
  watch:{
    facility(){
      this.pImItem();
    }
  }
}
</script>